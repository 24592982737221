<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import type { User } from '../../interfaces/types';
    import { Textarea } from '$lib/components/ui/textarea/index.js';
    import PaperPlaneIcon from '@/Icons/PaperPlaneIcon.svelte';

    export let currentUser: User;

    const dispatch = createEventDispatcher();

    let message = '';
    let isLoading = false;

    function textAreaAdjust(event: Event) {
        const textarea = event.target as HTMLTextAreaElement;
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
    }

    async function handleSubmit() {
        if (!message.trim() || isLoading) return;

        isLoading = true;
        try {
            await dispatch('addComment', message.trim());
            message = '';
        } finally {
            isLoading = false;
        }
    }

    $: isSubmitDisabled = !message.trim() || isLoading;
</script>

<div class="new-post-comment-area px-3">
    <div class="flex items-center justify-center">
        <img class="h-12 w-12 rounded-full" src="{currentUser.avatar}" alt="{currentUser.name}" />
        <div class="input-group mx-3 flex-1">
            <Textarea
                bind:value="{message}"
                class="comment-textarea comment-text"
                placeholder="Write a message.."
                on:input="{textAreaAdjust}"
                disabled="{isLoading}"
            />
            <div class="input-group-append z-10 flex items-center justify-center">
                <span class="h-pill h-pill-primary trigger mr-3 rounded" title="Like">😊</span>
            </div>
        </div>
        <div class="pl-2">
            <button
                class="btn btn-outline-primary btn-rounded-icon mb-0 p-2 transition-all duration-200 ease-in-out"
                on:click="{handleSubmit}"
                disabled="{isSubmitDisabled}"
                class:cursor-not-allowed="{isSubmitDisabled}"
                class:opacity-50="{isSubmitDisabled}"
            >
                <div class="flex items-center justify-center">
                    <span>
                        <PaperPlaneIcon />
                    </span>
                </div>
            </button>
        </div>
    </div>
</div>
